import { plannerCourseSectionDetailsToInfo } from '@/models';
import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { PublishedWorkEditViewModel } from '@/viewmodels';
import { Box, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import { addMinutes } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { CourseSectionPicker } from '../../shared';
import { DateTimePicker, Subheader } from '../../utils';
import { WorkEditAddAttachment, WorkEditAttachmentsList, WorkIconPicker, WorkImportanceLevelPicker } from '../../work';

export interface PublishedWorkEditProps {
  sx?: SxProps;
  className?: string;
  viewModel: PublishedWorkEditViewModel;
}

export const PublishedWorkEdit = observer(({ sx, className, viewModel }: PublishedWorkEditProps) => {
  const { dateService } = useServices();
  const strings = LocalizedStrings.publishedWork.edit;

  return (
    <Stack sx={sx} className={className} spacing={3} pb={viewModel.error != null ? 3 : 0}>
      <Box>
        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} sm={12} md={8}>
            <Stack direction="column" spacing={2}>
              <Box>
                <Subheader>{strings.titleSectionTitle()}</Subheader>
                <TextField
                  value={viewModel.title}
                  placeholder={viewModel.icon?.iconName}
                  fullWidth
                  disabled={viewModel.isApplying}
                  onChange={(e) => (viewModel.title = e.target.value)}
                />
              </Box>

              <Box>
                <Subheader>{strings.instructionsSectionTitle()}</Subheader>
                <TextField
                  value={viewModel.description}
                  fullWidth
                  multiline
                  onChange={(e) => (viewModel.description = e.target.value)}
                  minRows={5}
                  disabled={viewModel.isApplying}
                />
              </Box>

              {viewModel.attachments.length > 0 && <WorkEditAttachmentsList attachments={viewModel.attachments} />}

              <WorkEditAddAttachment onCreateAttachment={(a) => viewModel.addAttachments(a)} />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Stack direction="column" spacing={2}>
              <Box>
                <Subheader>{strings.informationSectionTitle()}</Subheader>
                <Stack direction="column" spacing={2}>
                  <WorkIconPicker
                    workIcons={viewModel.workIcons}
                    label={strings.workIconTitle()}
                    disabled={viewModel.isApplying}
                    value={viewModel.icon}
                    onChange={(id) => viewModel.setWorkIcon(id)}
                    elevation={2}
                  />

                  <WorkImportanceLevelPicker
                    disabled={viewModel.isApplying}
                    value={viewModel.importanceLevel}
                    onChange={(level) => (viewModel.importanceLevel = level)}
                    label={strings.importanceLevelTitle()}
                    elevation={2}
                  />

                  <TextField
                    label={strings.maxGradeInputLabel()}
                    type="number"
                    value={viewModel.maxGrade}
                    onChange={(e) =>
                      (viewModel.maxGrade = e.currentTarget.value.length > 0 ? +e.currentTarget.value : undefined)
                    }
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography variant="body1" fontWeight="500">
          {strings.publishToSectionTitle()}
        </Typography>

        <Grid container spacing={{ xs: 0, md: 3 }}>
          <Grid item xs={12} sm={12} md={8}>
            <Subheader>{strings.coursePickerSectionTitle()}</Subheader>
            <Stack direction="column" spacing={1}>
              <CourseSectionPicker
                label={strings.coursePickerTitle()}
                disabled={viewModel.isApplying || !viewModel.isNewWork}
                selectedCourseId={viewModel.courseSection?.courseSection?.id}
                allCourseSections={viewModel.allCourseSections.map(plannerCourseSectionDetailsToInfo)}
                onChange={(id) => viewModel.setCourseSection(id)}
                canSelectNone={false}
                elevation={2}
              />

              <FormControlLabel
                label={strings.publishToExternalSourceOption(
                  localizedExternalSourceName(viewModel.courseSection.courseSection!.externalSource!.sourceName)
                )}
                control={<Checkbox />}
                checked={viewModel.alsoPublishInExternalSource}
                onChange={(_, checked) => (viewModel.alsoPublishInExternalSource = checked)}
                disabled={!viewModel.isNewWork}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Subheader>{strings.dueDateSectionTitle()}</Subheader>
            <Stack spacing={2}>
              <DateTimePicker
                direction="column"
                value={viewModel.dueDate}
                disabled={viewModel.isApplying}
                canClearDate={false}
                hasTime={!viewModel.isAllDay}
                datePickerLabel={strings.dueDateDateLabel()}
                timePickerLabel={strings.dueDateTimeLabel()}
                datePickerKind="user-dashboard-calendar"
                highlightedDates={(from, to) => viewModel.getDatesWithOccurrenceForCourseSection(from, to)}
                getPeriodTimesForDate={(date) => viewModel.getCourseSectionOccurrencesStartTimeForDate(date)}
                dateFormat={strings.dueDateDateFormat()}
                error={!viewModel.isDateValid ? strings.dueDateErrorLabel() : undefined}
                onChange={(value, hasTime) => {
                  if (value == null) {
                    return;
                  }

                  viewModel.dueDate = value;
                  viewModel.isAllDay = !hasTime;
                }}
              />

              <Stack spacing={1}>
                <FormControlLabel
                  disabled={!viewModel.canSchedulePublishTime}
                  control={<Checkbox />}
                  label={strings.availableImmediatelyOption()}
                  checked={viewModel.scheduledPublishTime == null}
                  onChange={(_, checked) => {
                    if (!checked && viewModel.scheduledPublishTime == null) {
                      viewModel.scheduledPublishTime = addMinutes(dateService.now, 1);
                    } else if (checked) {
                      viewModel.scheduledPublishTime = undefined;
                    }
                  }}
                />

                <MuiDateTimePicker
                  minDateTime={dateService.now}
                  maxDateTime={viewModel.dueDate}
                  slotProps={{ textField: { fullWidth: true } }}
                  label={strings.scheduledPublishTimePickerLabel()}
                  value={viewModel.scheduledPublishTime ?? dateService.now}
                  onChange={(e) => (viewModel.scheduledPublishTime = e ?? undefined)}
                  disabled={viewModel.scheduledPublishTime == null || !viewModel.canSchedulePublishTime}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
});
